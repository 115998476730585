import React, { Component } from "react";
import { connect } from "react-redux";
// import "../Commande-client-devis/Styling.css";
import { getClientHeader } from "../../redux/actions/ClientActions";
// import "./ClientsFournisseurs.scss";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import "../../App.css";
import { SelectTest } from "../../redux/actions/TestAction";
import AddIcon from "@material-ui/icons/Add";
import InputBase from "@material-ui/core/InputBase";
import { Row, Col } from "reactstrap";
import AddMVTCAISModal from "./add-modal/AddMVTCAISModal";
import GetClientByID from "./GetMVTCAIByID";
import { Redirect } from "react-router-dom";
import { SelectClient } from "../../redux/actions/GetClients";
import {SelectCaisse} from "../../redux/actions/GetCaisse";
import SearchIcon from "@material-ui/icons/Search";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import { Link } from "react-router-dom";
import "./style.css";
import {
  Breadcrumbs,
  Paper,
  TableSortLabel,
  Button,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";


import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import * as XLSX from 'xlsx';
import { Divider, Fab, IconButton, Snackbar } from "@material-ui/core";
import "./caisse.scss"
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import translate from "../../i18n/translate";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import moment from 'moment';
const actions = [
  // { icon: <MailOutlineIcon />, name: "Mail" },
  // { icon: <EditIcon />, name: "Modifier" },
  // { icon: <DeleteOutlineIcon />, name: "Supprimer" },
  // { icon: <ListAltIcon />, name: "Relevé" },
];
const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class MvtCais extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      addModalShow: false,
      GetByIdModalShow: false,
      loggedIn,
      defaultdate: date,
      rechs: [],
      icon: false,
      rechercheclicked: false,
      order: "asc",
      orderBy: "",
      raisoc: "",
      Details:[],
    };
  }
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  toggle = () => this.setState({ modal: !this.state.modal });

  componentDidMount() {
    console.log("detaille",this.state.Details)
    this.props.getClientHeader();
    
    this.props.SelectCaisse(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database);

    document.title = "Fiche Client";
  }

  rechercheHandler = (event) => {
    fetch(
      `https://www.24-crm.com/webservice/api/caisse?codecais=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, rechercheclicked: true }));
  };
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };
  getTotalsoldfac = () => {
    let totalsoldfac = 0;
    if(this.state.rechercheclicked){
      this.state.rechs.forEach((article) => {
       
        totalsoldfac+= parseFloat(Number(article.soldfac));
    })
  }
  else{
    this.props.clients.clients.forEach((article) => {
      totalsoldfac+= parseFloat(Number(article.soldfac));
    })
  };
    return totalsoldfac.toFixed(3); // arrondi à 4 chiffres après la virgule
  };


  
  exportToExcel = () => {
    if(this.state.rechercheclicked){
    
    // Préparation des données
    const data =  this.state.rechs.map(item => {
      const date = new Date(item.datopr);
      const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
      const piece=item.typach;
      const piece1=item.pj;
      const pj=`${piece}--${piece1}`;
        return [
         
           item.numopr,
           item.libelle,
          item.numcais,
           item.montant,
          item.modreg,
         item.depense,
          item.VENDEUR,
]
            // Ajoutez d'autres champs au besoin
        ;
    });

// // Calcul des totaux
// const totals = data.reduce(
//   (acc, row) => {
 
//     acc[5] += row[5]; // soldfac
//     acc[6] += row[6]; // soldfacbl
//     return acc;
//   },

//   ['', '', 'T o t a u x', '', '', 0, 0]
// );

// Ajout des totaux à la fin des données
// data.push(totals);
    // Création d'un nouveau classeur
    const classeur = XLSX.utils.book_new();
    const feuille = XLSX.utils.json_to_sheet(data);
// Ajout du titre de tableau (première ligne)
const title = '                       Liste des Mouvements du caisses             ';
feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
feuille['!merges'] = [{ s: { r: 0, c: 0 ,alignment: { horizontal: 'center', vertical: 'center' } }, e: { r: 2, c: 6 }  }]; // Fusionner les cellules pour le titre


// Définition de l'entête du tableau (deuxième ligne)
const header = ['code', 'Libellé', 'Caisse', 'Montant', 'Mode rélement', 'Dépense', 'Utilisateur'];
XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });

// Ajout des données de tableau à partir de la cinquième ligne
XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });
// Ajout de la feuille au classeur
XLSX.utils.book_append_sheet(classeur, feuille, "caisse");
   
    // Génération d'un fichier téléchargeable
    XLSX.writeFile(classeur, "MVT caisse .xlsx");
  }

  else{
  
    // Préparation des données
    const data =  this.props.Caisses.Caisses.map(item => {
      const date = new Date(item.datopr);
      const formattedDate = ` ${ moment(date).format("DD/MM/YYYY")}`;
      const piece=item.typach;
      const piece1=item.pj;
      const pj=`${piece}--${piece1}`;
        return [
         
           item.numopr,
           item.libelle,
          item.numcais,
           item.montant,
          item.modreg,
         item.depense,
          item.VENDEUR,
]
            // Ajoutez d'autres champs au besoin
        ;
    });

// // Calcul des totaux
// const totals = data.reduce(
//   (acc, row) => {
 
//     acc[5] += row[5]; // soldfac
//     acc[6] += row[6]; // soldfacbl
//     return acc;
//   },

//   ['', '', 'T o t a u x', '', '', 0, 0]
// );

// Ajout des totaux à la fin des données
// data.push(totals);
    // Création d'un nouveau classeur
    const classeur = XLSX.utils.book_new();
    const feuille = XLSX.utils.json_to_sheet(data);
// Ajout du titre de tableau (première ligne)
const title = '                     Liste des Mouvements du caisses             ';
feuille['A1'] = { v: title, t: 's' }; // Définir le titre comme une chaîne de texte
feuille['!merges'] = [{ s: { r: 0, c: 0 ,alignment: { horizontal: 'center', vertical: 'center' } }, e: { r: 2, c: 6 }  }]; // Fusionner les cellules pour le titre


// Définition de l'entête du tableau (deuxième ligne)
const header = ['code', 'Libellé', 'Caisse', 'Montant', 'Mode rélement', 'Dépense', 'Utilisateur'];
XLSX.utils.sheet_add_aoa(feuille, [header], { origin: 'A4' });

// Ajout des données de tableau à partir de la cinquième ligne
XLSX.utils.sheet_add_aoa(feuille, data, { origin: 'A5' });
// Ajout de la feuille au classeur
XLSX.utils.book_append_sheet(classeur, feuille, "caisse");
   
    // Génération d'un fichier téléchargeable
    XLSX.writeFile(classeur, "MVT caisse .xlsx");
  };
   
}


  render() {


    const rowCount = stableSort(
      this.props.Caisses.Caisses,
      getComparator(this.state.order, this.state.orderBy)
    ).length;
    
    console.log(`Nombre de lignes affichées clients: ${rowCount}`);
    const { darck } = this.props;
    const classes = {
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    };
    let addModalClose1 = () => this.setState({ addModalShow: false });
    let GetByIdModalClose = () => this.setState({ GetByIdModalShow: false });

    const {

    numopr,
    libelle,
    numcais,
    montant,
    modreg,
    depense,
    VENDEUR,
    datopr,
    numchq,
    matban,
    datech,
    sens,
    heur,
    numoprdebit,
    Emp,
    } = this.state;
    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <main className="gestion-des-clients">
          <Breadcrumbs aria-label="breadcrumb" fontSize="small">
            <Link to={"/"}>
              <StyledBreadcrumb
                component="a"
                label="Vente"
                icon={
                  <HomeIcon
                    style={{ color: `${darck ? "" : "#fff"}` }}
                    fontSize="small"
                  />
                }
                style={{
                  color: `${darck ? "" : "#fff"}`,
                  backgroundColor: `${darck ? "" : "#172431"}`,
                  border: "2px solid #fff",
                }}
              />
            </Link>
            {/* <div>/</div> */}
            <StyledBreadcrumb
              component="a"
              color="Primary"
              style={{
                color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
                backgroundColor: `${darck ? "" : "#172431"}`,
                border: `${darck ? "" : "2px solid #fff"}`,
              }}
              // href="/fiche-client"
              label="Mouvements de caisse"
              icon={<PersonIcon fontSize="small" />}
            />
            
          <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            onClick={this.exportToExcel}
            // href="/fiche-article"
            label="Exporter vers Excel"
            icon={<CloudUploadOutlined fontSize="small" />}
          />
          </Breadcrumbs>

          <br />
          <Paper
            style={{
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            // elevation={0.5}
            elevation={2}
          >
            <Row style={{ flexWrap: "nowrap" }}>
              {/* <InputGroupText>
              <SearchIcon className="search-icon" color="primary" />
            </InputGroupText> */}

              <Col sm={10}>
                <div style={{ display: "flex", flexWrap: "nowrap" ,marginTop:"5px"}}>
                  <SearchIcon
                    style={{ color: `${darck ? "" : "#fff"}` }}
                    color="primary"
                  />

                  &nbsp;&nbsp;
                  <InputBase
                    margin="dense"
                    // fullWidth={true}
                    placeholder="Rechercher"
                    onChange={this.rechercheHandler}
                    onClick={this.toggle}
                    style={{
                      color: `${darck ? "" : "#fff"}`,
                      backgroundColor: `${darck ? "" : "#172431"}`,
                    }}
                  />
                </div>
              </Col>
              <Col sm={2} style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  color="primary"
                  onClick={() => this.setState({ addModalShow: true })}
                  style={{ color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}` }}
                >
                  <AddIcon
                    style={{
                      color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}`,
                    }}
                  />
                  Mouvements de caisse
                </Button>
                {/* <InputGroupAddon addonType="append">
              
            </InputGroupAddon> */}
              </Col>
            </Row>
          </Paper>

          <br />

          <div className="tabcfMVT">
            <table striped>
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />
              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.state.rechercheclicked
                    ? this.state.rechs
                    : this.props.Caisses.Caisses,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    style={{
                      cursor: "pointer",
                      paddingRight: "20px",
                      height: "100%",
                    }}
                    key={test.numopr}
                    onClick={() => { 
                      fetch(
                        `https://www.24-crm.com/webservice/api/caisse?codecais=${test.numopr}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                        { method: "GET",
                              headers: { Accept: "application/json", "Content-Type": "application/json" },
                            }
                          )
                          .then((response) => response.json())
                          .then((data) => { this.setState({ Details: data }); });
                      this.setState({
                        GetByIdModalShow: true,
                        numopr: test.numopr,
                        libelle: test.libelle,
                        numcais: test.numcais,
                        montant: test.montant,
                        modreg: test.modreg,
                        depense: test.depense,
                        VENDEUR: test.VENDEUR,
                        datopr: test.datopr,
                        numchq: test.numchq,
                        matban: test.matban,
                        datech: test.datech,
                        sens:test.sens,
                        heur: test.heur,
                        numoprdebit:test.numoprdebit,
                        Emp: test.Emp,
                      });
                    }}
                  >
                    <td>
                      <span style={{color: `${darck ? "#DF5F10" : "#fff"}`, paddingRight: "25px" }}>
                        {test.numopr}
                      </span>
                    </td>

                    <td>
                      <span
                        style={{
                          color: `${darck ? "#182532" : "#fff"}`,
                          paddingRight: "10px",
                        }}
                      >
                        {test.libelle}
                      </span>
                    </td>
                    <td>
                     
                        <span
                          style={{ color: `${darck ? "#182532" : "#fff"}` }}
                        >{`${test.numcais}`}</span>
                   
                    </td>

                    <td>
                      <span style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                        {test.montant}
                      </span>
                    </td>

                
                      <td>
                        
                        <span
                          style={{ color: `${darck ? "#182532" : "#fff"}` }}
                        >
                          {test.modreg}
                        </span>
                      </td>
                  

                    <td>
                      <span style={{ color: "green", paddingLeft: " 60px" }}>
                        {test.depense}
                      </span>
                    </td>
                    <td>
                      <span style={{ color: "#0D47A1", paddingLeft: "10px" }}>
                        {test.VENDEUR}
                      </span>
                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>


   
   <br />

 {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}


           
  <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "0px", right: "10px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<PrintIcon openIcon={<PrintIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                      
                      }}
                    />
                  ))}
                    {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            marginTop: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
              
                   </Col>
            </Row>  
            <div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  
  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
               Matricule Fiscal  : {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}{" "}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.props.Auth.username}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <> Mouvements de caisse</>
            
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "390px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
          
                  
            
  
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                {/* Article  : {this.props.codearticle}  */}
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
            <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                  
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("code")}</th>
                <th style={{ width: "10%" ,   border: "1px solid gray"}}>{translate("Libellé")}</th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Caisse")} </th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Montant")}</th>
                <th style={{ width: "15%"  ,   border: "1px solid gray"}}>{translate("Mode")}</th>
                 <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Dépense")}</th>
                 <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Utilisateur")}</th>
               
                </tr> 
             
                </thead>
            <tbody>
          {this.state.rechercheclicked?(<>
            {this.state.rechs.map((t, i) => (

         
<tr
  key={i}
  style={{
    textAlign: "center",
    height: "40px",
    border: "1px solid gray",
 
  }}>
{/* ///////////////////////////////////////// codcli ///////////////////////*/}
<td  style={{ color: `${darck ? "#DF5F10" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 

{t.numopr}

</td>
{/* ///////////////////////////////////////////// raisoc ///////////////////*/}
{!t.libelle? (
      <td>
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >
          --
        </span>
      </td>
    ) : (
      <td>
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >
          {t.libelle}
        </span>
      </td>
    )} 
{/* ////////////////// telephone ///////////*/}
<td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 

        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >{t.numcais }</span>
     
</td>

{/* ////////////////// adresse ///////////*/}

      <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
       
          {Number(t.montant).toFixed(3) }
       
      </td>

{/* ////////////////// Ville ///////////*/}

      <td>
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >
          {t.modreg}
        </span>
      </td>

{/* //////////////////////////////////////////// soldfac ////////////////////////////*/}
<td style={{ color: `${darck ? "green" : "#fff"}` ,   border: "1px solid gray" }}> {t.depense}</td>
{/* //////////////////////////////////////////// soldfacbl ////////////////////////////*/}
<td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,   border: "1px solid gray" }}>  {t.VENDEUR} </td>


  
  
    
 
   </tr>  
  
  ))} 
          </>):(<>
            {this.props.Caisses.Caisses.map((t, i) => (

         
         
<tr
  key={i}
  style={{
    textAlign: "center",
    height: "40px",
    border: "1px solid gray",
 
  }}>
{/* ///////////////////////////////////////// codcli ///////////////////////*/}
<td  style={{ color: `${darck ? "#DF5F10" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 

{t.numopr}

</td>
{/* ///////////////////////////////////////////// raisoc ///////////////////*/}
{!t.libelle? (
      <td>
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >
          --
        </span>
      </td>
    ) : (
      <td>
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >
          {t.libelle}
        </span>
      </td>
    )} 
{/* ////////////////// telephone ///////////*/}
<td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 

        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >{t.numcais }</span>
     
</td>

{/* ////////////////// adresse ///////////*/}

      <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}>
       
          {Number(t.montant).toFixed(3) }
       
      </td>

{/* ////////////////// Ville ///////////*/}

      <td>
        <span
          style={{ color: `${darck ? "#182532" : "#fff"}` }}
        >
          {t.modreg}
        </span>
      </td>

{/* //////////////////////////////////////////// soldfac ////////////////////////////*/}
<td style={{ color: `${darck ? "green" : "#fff"}` ,   border: "1px solid gray" }}> {t.depense}</td>
{/* //////////////////////////////////////////// soldfacbl ////////////////////////////*/}
<td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,   border: "1px solid gray" }}>  {t.VENDEUR} </td>


  
  
    
 
   </tr>    
  
  ))} </>)}
              
                 </tbody>
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
     

                <br />
           
      
          {/* <Col>
                <div
                  style={{ width: "100%", display: "flex",alignItems: "center",flexDirection: "row", textAlign: "center", justifyContent: "space-between", }}  >
                </div>
                <div
                  style={{
                    marginTop: "10px",
                     border: "1px solid gray",
                    // borderStyle: "solid",
                    height: "30px",
                    width: "100%",
                    //borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                   <h6
              style={{
                marginLeft: "20px",
                flexDirection: "column",
                // marginTop: "25px",
                // marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >  
            
                <label
          style={{ color: `${darck ? "#0D47A1" : "#fff"}`
          , fontSize: "14px" }}

         >TOTAUX : </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
         <span style={{ color: "blac",paddingLeft: "500px" }}>
          
         
         <label>
         <span style={{ color: "green" }}>
         {this.getTotalsoldfac()}
       </span>
     </label>    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     <label>
              <span style={{ color: "#0D47A1" }}>
         {this.getTotalsoldfacbl()}
       </span>
       </label> 
         </span>
            </h6>
            <br/>
              
             
                 <div
                    style={{
                      width: "10%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                  </div>
                </div>
              </Col>    
                */}
           
           
               
              </div>
            
          
            
            </div>
          </div>

  {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
            
          <AddMVTCAISModal
            allCodeClient={this.props.Caisses.Caisses}
            show={this.state.addModalShow}
            onHide={addModalClose1}
          />

          <GetClientByID
            show={this.state.GetByIdModalShow}
            onHide={GetByIdModalClose}
            numopr={numopr}
            libelle={libelle}
            numcais={numcais}
            montant={montant}
            modreg={modreg}
            depense={depense}
            VENDEUR={VENDEUR}
            datopr={datopr}
            numchq={numchq}
            matban={matban}
            datech={datech}
            sens={sens}
            heur={heur}
            numoprdebit={numoprdebit}
            Emp={Emp}
            Details={this.state.Details}
          />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    DataTables: state.DataTablesReducer,
    tests: state.tests,
    SearchingResult: state.SearchingReducer,
    clients: state.clients,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
    Caisses:state.Caisses,

  };
};
const mapDispatchToProps = (dispatch) => ({
  SelectCaisse: (a, b, c, d, e) => {
    dispatch(SelectCaisse(a, b, c, d, e));
  },
  getClientHeader: () => {
    dispatch(getClientHeader());
  },
  SelectTest: () => {
    dispatch(SelectTest());
  },
  SelectClient: (a, b, c, d, e) => {
    dispatch(SelectClient(a, b, c, d, e));
  },
});
const ConnectedMvtCais = connect(
  mapStateToProps,
  mapDispatchToProps
)(MvtCais);
export default ConnectedMvtCais;

// les fonctions utilisées pour le trie des colonnes

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: "numopr",
    numeric: false,
    disablePadding: true,
    label: "Code",
  },

  {
    id: "libelle",
    numeric: true,
    disablePadding: false,
    label: "Libellé",
  },
  {
    id: "numcais",
    numeric: true,
    disablePadding: false,
    label: "Caisse",
  },

  {
    id: "montant",
    numeric: true,
    disablePadding: false,
    label: "Montant",
  },

  { id: "modreg", numeric: false, disablePadding: false, label: "Mode" },

  {
    id: "depense",
    numeric: true,
    disablePadding: false,
    label: "Dépense",
  },
  {
    id: "VENDEUR",
    numeric: true,
    disablePadding: false,
    label: "Utilisateur",
  },
];

function EnhancedTableHead(props) {
  const { classes, darck, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
    },
  },
}))(Chip);
